<template>
  <div class="index">
    <el-carousel :height="bannerHeight" >
      <el-carousel-item v-for="(item,key) in images" :key="key">
        <img v-resize="handleResize" id="bannerImg" class="imgclass" v-lazy="item.url">
      </el-carousel-item>
    </el-carousel>
    <About></About>
    <Case></Case>
    <Factory></Factory>
    <Partner></Partner>
    <News></News>
  </div>
</template>

<script>
export default {
  props:{
    route:{
      type:String
    },
  },
  data () {
    return {
      images: [
        { url: require('@/assets/img/11.jpg') },
        { url: require('@/assets/img/22.jpg') },
        { url: require('@/assets/img/44.jpg') }
      ],
      bannerHeight: null
    }
  },
  watch:{
    route(val,oldval){
      console.log(val,oldval)
      if(!val){
        return
      }
      let target = document.getElementById(val)
      target.scrollIntoView({behavior:'smooth', block: 'center'})
    }
  },
  components: {
    Partner: () => import('../components/partner.vue'),
    Factory: () => import('../components/factory.vue'),
    About: () => import('../components/about.vue'),
    Case: () => import('../components/case.vue'),
    News: () => import('../components/news.vue'),
    Footer: () => import('../components/footer.vue')
  },
  methods: {
    handleResize ({ width, height }) {
      this.bannerHeight = height
    }
  },
  mounted () {  }
}
</script>

<style lang='less'>
.index{
  .imgclass{
    width: 100%;
    height: 84vh;
  }
/*  // @media only screen and (min-width: 1280px){
  //   .imgclass{
  //     width: 70vw;
  //     height: 55vh;
  //   }
  //   .el-carousel{
  //     width: 70vw;
  //     height: 55vh !important;
  //     margin: 0 auto;
  //   }
  // }
  // @media only screen and (max-width: 1279px){
  //   .imgclass{
  //     width: 70vw;
  //     height: 55vh;
  //   }
  //   .el-carousel{
  //     width: 70vw;
  //     margin: 0 auto;
  //   }
  // }
  // @media only screen and (max-width: 960px){
  //   .imgclass{
  //     width: 100vw;
  //     height: 40vh;
  //   }
  // } */
}
</style>
