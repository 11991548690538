<template>
  <div id="app">
    <el-container>
      <el-header height="100px">
        <Header @emitRoute="emitRoute" ></Header>
      </el-header>
      <el-main>
        <router-view :route="route"></router-view>
      </el-main>
      <Footer></Footer>
    </el-container>
  </div>
</template>

<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
export default {
  data(){
    return {
      route: null
    }
  },
  components: {
    Header,
    Footer
  },
  methods:{
    emitRoute(val){
      this.route = val
      setTimeout(()=>{
        this.route = null
      },300)
    },
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media only screen and (min-width: 1280px){
  .el-container{
    width: 100%;
    margin: 0 auto;
    .el-header{
      // background: pink
    }
    .el-main{
      padding: 0;
    }
  }
}
@media only screen and (max-width: 1279px){
  .el-container{
    width: 100%;
    margin: 0 auto;
    .el-header{
      // background: pink
    }
    .el-main{
      padding: 0;
    }
  }
}
@media only screen and (max-width: 960px){
  .el-container{
    width: 100%;
    margin: 0 auto;
    .el-header{
      // background: pink
    }
    .el-main{
      padding: 0;
    }
  }
}

</style>
