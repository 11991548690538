import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/about',
    name: 'about',
    component: resolve => require(['@/components/about.vue'], resolve)
  },
  {
    path: '/case',
    name: 'case',
    component: resolve => require(['@/components/case.vue'], resolve)
  },
  {
    path: '/factory',
    name: 'factory',
    component: resolve => require(['@/components/factory.vue'], resolve)
  },
  {
    path: '/partner',
    name: 'partner',
    component: resolve => require(['@/components/partner.vue'], resolve)
  },
  {
    path: '/news',
    name: 'news',
    component: resolve => require(['@/components/news.vue'], resolve)
  },
]

const router = new VueRouter({
  routes
})

export default router
