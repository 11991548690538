<template>
  <div class="footer">
    <footer class="lsh-footer fadeInUp">
      <div class="footer-cover clearfix">
        <div class="footer-service">
            <div class="footer-ser-tel siteInline">
              <p class="service-tip">服务热线</p>
              <strong class="service-tel">15962672662</strong>
              <p class="tips">（工作日 早8：30-18：00）</p>
            </div>
            <div style="width:100%;height: 20px;float: left;"></div>
            <div class="footer-ser-location">
                <ul class="clearfix">
                  <li>公司总部：蓝珊瑚工业自动化有限公司</li>
                  <li>地址：苏州市吴江区湖心西路666号</li>
                  <li>电话：15962672662</li>
                  <li>邮箱：<a href="mailto:ronggch@163.com" style="color:#fff;text-decoration: underline">15962672662@163.com</a></li>
                </ul>
            </div>
        </div>
        <div class="footer-link clearfix">
          <div class="square-code">
              <img v-lazy="images.url" alt="蓝珊瑚工业自动化有限公司" with="150" height="150">
              <p><span style="font:bold;font-size: 14px;color:#fff;">加入我们</span></p>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-box-bottom">
      <div class="footer-inner clearfix">
        <p class="footer-box-tip">
          <a style="text-decoration: none;">
            <span>版权所有 © 2006-2021</span>
            蓝珊瑚工业自动化有限公司<span></span>
            <span id="szgs_stat_icon_29590"></span>
          </a>
          <a href="javascript:void(0);" target="_blank" style="text-decoration: underline;padding-left: 10px">网站地图</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      images: {
        url: require('@/assets/img/squarecode.jpg'),
      }
    }
  }
}
</script>

<style lang='less'>
.footer{
  .lsh-footer{
    width: 100%;
    background: #3e3a39;
    .footer-cover {
      min-width: 1200px;
      max-width: 1440px;
      margin: 0 auto;
      padding-bottom: 80px;
      .footer-service {
        float: left;
        width: 42.4%;
        padding-top: 90px;
        .siteInline {
          display: inline-block;
          float: left;
          width: 50%;
          .service-tip {
            font-size: 14px;
            color: #fff;
          }
          .service-tel {
            display: block;
            margin-top: 10px;
            font-family: Arial;
            font-size: 30px;
            color: #f8b62c;
          }
          .tips {
            font-size: 14px;
            color: #efb31e;
            margin-left: -7px;
          }
        }
        .footer-ser-location {
          margin-top: 25px;
          ul{
            list-style: none;
            li{
              float: left;
              width: 50%;
              font-size: 14px;
              color: #fff;
              line-height: 24px;
              list-style-type: none;
              display: list-item;
              text-align: -webkit-match-parent;
            }
          }
          .clearfix{
            *zoom: 1;
          }
          .clearfix:after{
            content: "";
            display: block;
            height: 0;
            clear:both;
            visibility: hidden;
          }
        }
      }
      .footer-link {
        float: right;
        margin-right: 150px;
        padding-top: 90px;
        .square-code {
          background-color: aqua;
          width: 150px;
          height: 150px;
        }
      }
      .clearfix{
        *zoom: 1;
      }
      .clearfix:after{
        content: "";
        display: block;
        height: 0;
        clear:both;
        visibility: hidden;
      }
    }
    .clearfix{
      *zoom: 1;
    }
    .clearfix:after{
      content: "";
      display: block;
      height: 0;
      clear:both;
      visibility: hidden;
    }
  }
  .footer-box-bottom {
    width: 100%;
    background: #656161;
    .footer-inner {
      min-width: 1200px;
      max-width: 1440px;
      margin: 0 auto;
      .footer-box-tip {
        float: left;
        height: 60px;
        line-height: 60px;
        a {
          font-size: 12px;
          color: #999;
          font-weight: lighter;
        }
      }
    }
    .clearfix{
      *zoom: 1;
    }
    .clearfix:after{
      content: "";
      display: block;
      height: 0;
      clear:both;
      visibility: hidden;
    }
  }
}
</style>
