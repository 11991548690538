import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import Vant from 'vant'
// import 'vant/lib/index.css'
// import 'lib-flexible/flexible.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import '@/utils/resize.js'

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

Vue.prototype.axios = axios

const getCookie = (cookieName) => {
  var strCookie = document.cookie
  var arrCookie = strCookie.split('; ')
  for (var i = 0; i < arrCookie.length; i++) {
    var arr = arrCookie[i].split('=')
    if (cookieName === arr[0]) {
      return arr[1]
    }
  }
  return ''
}
Vue.prototype.getCookie = getCookie
// Vue.use(Vant)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
