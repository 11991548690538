<template>
  <div class="header">
    <div class="lsh-header">
      <el-row style="">
        <el-col :span="8">
          <a class="lsh-logo" href="/">
            <img style="width: 150px;" v-lazy="images.url" alt="蓝珊瑚工业自动化有限公司">
          </a>
        </el-col>
        <el-col :span="16">
          <ul class="lsh-ul" style="">
            <li  @click="getRoute('about')">
              <!-- <router-link to="/about">关于我们</router-link> -->
              <a href="javascript:void(0);">关于我们</a>
            </li>
            <li class="dropdown">
            <span class="dropbtn" @click="getRoute('lshCase')">行业案例</span>
              <div class="dropdown-content">
                <a href="javascript:void(0);" @click="getRoute('3C')">3C行业</a>
                <a href="javascript:void(0);" @click="getRoute('car')">汽车行业</a>
                <a href="javascript:void(0);" @click="getRoute('battery')">锂电行业</a>
                <a href="javascript:void(0);" @click="getRoute('medical')">医疗行业</a>
              </div>
            </li>
            <li @click="getRoute('lshFactory')">
              数字化工厂
            </li>
            <li @click="getRoute('lshPartner')">
              行业伙伴
            </li>
            <li @click="getRoute('lshNews')">
              新闻动态
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      images: {
        url: require('@/assets/img/bulelogo.png')
      }
    }
  },
  methods:{
    getRoute(val){
      this.$emit('emitRoute',val)
    }
  }
}
</script>

<style lang='less'>
.header{
  height: 100%;
  .lsh-header{
    padding-left: 85px;
    max-width: 1920px;
    min-width: 1200px;
    height: 106px;
    background-color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
    .lsh-ul{
      display: inline-flex;
      list-style: none;
      font-size: 18px;
      font-weight: 700;
      line-height: 100px;
      cursor: pointer;
      li{
        border-top: 5px solid #ffffff;
        a{
          text-decoration: none;
          color: #000;
        }
        /* li>a:hover{
          color: #4884ff;
        } */
      }
      li:first-child{
        margin: 0 30px;
      }
      li+li{
        margin: 0 30px;
      }
      li:hover{
        border-top: 5px solid #34e2ff;
      };
      .dropbtn {
          padding: 16px;
          font-size: 18px;
          border: none;
          cursor: pointer;
      }

      .dropdown {
          position: relative;
          display: inline-block;
      }

      .dropdown-content {
          display: none;
          left: -26%;
          position: absolute;
          background-color: #f9f9f9;
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      }

      .dropdown-content a {
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          font-size: 16px;
          line-height:  20px;
      }

      .dropdown-content a:hover {background-color: #f1f1f1}

      .dropdown:hover .dropdown-content {
          display: block;
      }

      .dropdown:hover .dropbtn {
          /* background-color: #3e8e41; */
      }
    }
    .clearfix:after{/*伪元素是行内元素 正常浏览器清除浮动方法*/
      content: "";
      display: block;
      height: 0;
      clear:both;
      visibility: hidden;
    }
    .clearfix{
        *zoom: 1;/*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
    }
    .lsh-logo{
      float: left;
      height: 100px;
      line-height: 100px;
      img{
        vertical-align: middle;
      }
    }
  }
}
</style>
